import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";

import Video from '../video/VideoComponent';
import Footer from '../footer/FooterComponent';
import { Vector1 } from './vectores';

import './home.css';

import checkOutImg from './img/image-3.jpg';
import scheduleImg from './img/image-1.jpg';
import magicalImg from './img/image-4.jpg';
import iconImg from './img/icon.png';
import backgroundLine from './img/line.png';
import horarioImg from './img/horario.png';
import locationImg from './img/location.svg';

import magical_experience from './img/magical_experience.svg';

export default class Home extends Component {

  render() {
    return (
      <div className="Home">

        <Video />

        <div className="section1">
          <div className="partLeft">
            <h2>OUR GOAL IS TO CREATE AN OUTSTANDING CONCEPT IN A SHOP THAT CAN SERVE VARIETY OF PEOPLE ALL DAY LONG.</h2>
          </div>
          <div className="partRight">
            <div className="part1">
              <h5>Serving specialty coffee and introducing high-end coffee in the Qatari market, tasters and lovers can now try our different blends.</h5>
            </div>



            <div className="part2" style={{ backgroundImage: `url(${backgroundLine})` }}>
              <p>Le Petit Camion focuses to purchase the ingredients from the local suppliers as possible, keeping the business in the community or, at the least, in the state a part of our corporate social responsibility. We have a plan to support and engage the community.</p>
              <p><Link title="About" to="/about">know more about us</Link></p>
            </div>
          </div>
        </div>

        <div className="section2">
          <div className="partLeft">
            <p><Link className="vectorLink" title="Events" to="/events"><Vector1 /></Link></p>
            <img src={checkOutImg} alt="check out our upcoming events" />
          </div>
          <div className="partRight">
            <img src={scheduleImg} alt="schedule" />
            <img src={horarioImg} alt="schedule" />
          </div>
        </div>

        <div className="section3">
          <div className="partLeft">
            <img src={magical_experience} alt="magical coffee" />
          </div>
          <div className="partRight">
            <img src={magicalImg} alt="magical coffee" />
          </div>
        </div>

        <div className="section4">
          <div className="partLeft">
            <img src={iconImg} alt="le petit camion" />
          </div>
          <div className="partRight">
            <img src={locationImg} alt="location" />
            <h2><a href="https://www.google.com/maps/place/Villaggio+Mall/@25.260191,51.4408857,17z/data=!3m1!4b1!4m5!3m4!1s0x3e45da18a8ac2bef:0xad58f8007682d155!8m2!3d25.260191!4d51.4430744?hl=en-US">Villaggio Mall — Al Waab St, Doha</a></h2>
          </div>
        </div>

        <Footer />

      </div>
    );
  }

}
