import React, {Component} from 'react';

import './banner.css';


class Video extends Component {
    render () {
        return (
            <div id="bannerArea" style={{ backgroundImage: `url(${this.props.source})` }}>
              <div id="logo">
                <img src="img/logo.svg" alt="Le Petit Camion" />
              </div>
            </div>
        )
    }
};

export default Video;
