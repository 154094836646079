import React, {Component} from 'react';
import Swiper from 'react-id-swiper';

import './eventsSwiper.css';

import GalleryBox from '../gallery/GalleryComponent'

import swiperArrowLeft from './img/arrow_left.png';
import swiperArrowRight from './img/arrow_right.png';

import slide_e1_1 from './img/e1/e1_1.jpg';
import slide_e1_2 from './img/e1/e1_2.jpg';
import slide_e1_3 from './img/e1/e1_3.jpg';

import slide_e2_1 from './img/e2/e2_1.jpg';
import slide_e2_2 from './img/e2/e2_2.jpg';

import slide_e3_1 from './img/e3/e3_1.jpg';
import slide_e3_2 from './img/e3/e3_2.jpg';

import slide_e4_1 from './img/e4/e4_1.jpg';
import slide_e4_2 from './img/e4/e4_2.jpg';

export default class EventsSwiper extends Component {
  render() {

    const params = {
      slidesPerView: 4,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      renderPrevButton: () => <img src={swiperArrowLeft} alt="previous" className="swiper-button-prev" />,
      renderNextButton: () => <img src={swiperArrowRight} alt="next" className="swiper-button-next" />,
      spaceBetween: 30,
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        960: {
          slidesPerView: 2,
        },
      }
    }

    return (
      <Swiper {...params}>

        <button>
          <GalleryBox images={[slide_e4_1, slide_e4_2]} />
          <h5>15th - 21th Dec 2018</h5>
          <p>Darb Al Sai</p>
        </button>

        <button>
          <GalleryBox images={[slide_e3_1, slide_e3_2]} />
          <h5>11th - 13th Nov 2018</h5>
          <p>QDB Roward Conference</p>
        </button>

        <button>
          <GalleryBox images={[slide_e2_1, slide_e2_2]} />
          <h5>9th - 11th Nov 2018</h5>
          <p>LGCT Al Shaqab</p>
        </button>

        <button>
          <GalleryBox images={[slide_e1_1, slide_e1_2, slide_e1_3]} />
          <h5>17th - 21th Oct 2018</h5>
          <p>Qatar Motor Show</p>
        </button>

      </Swiper>

    )

  }
}
