import React, { Component } from 'react';

import Banner from '../banner/BannerComponent';
import Footer from '../footer/FooterComponent';
import EventsSwiper from '../events-swiper/EventsSwiperComponent';

import './events.css';

import backgroundImage from './img/backgroundImage.jpg';
import introImage from './img/intro.jpg';
import lineImage from './img/line.png';
import upcomingEvents from './img/upcoming_events.svg';
import pastEvents from './img/past_events.svg';

export default class Events extends Component {

  render() {
    return (
      <div className="Events">

        <Banner source={backgroundImage} />

        <div id="nextEvents">

          <div className="partLeft">
            <img src={introImage} alt="Camion" />
          </div>

          <div className="partRight">

            <div className="part1">
              <img src={upcomingEvents} alt="Upcoming Events" style={{ backgroundImage: `url(${lineImage})` }} />
              <ul>
                <li>
                  <h5>CTC</h5>
                  <p>29th Jan - 10th Feb 2019</p>
                </li>
                <li>
                  <h5>Kuwait</h5>
                  <p>TBC Feb 2019</p>
                </li>
                <li>
                  <h5>QIFF FESTIVAL</h5>
                  <p>TBC Mar 2019</p>
                </li>
                <li>
                  <h5>Ramadan</h5>
                  <p>TBC May 2019</p>
                </li>
              </ul>
            </div>

            <div className="part2">
              <h4>DO YOU WANT LE PETIT CAMION ON YOUR PRIVATE EVENT?</h4>
              <h4><a href="mailto:info@lepetitcamion.com">GET IN TOUCH!</a></h4>
            </div>
          </div>

        </div>

        <div id="pastEvents">
          <img src={pastEvents} alt="Past Events" />
          <EventsSwiper />
        </div>

        <Footer />

      </div>
    );
  }

}
