import React, {Component} from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './home/HomeComponent';
import About from './about/AboutComponent';
import Events from './events/EventsComponent';

import './App.css';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: 'closed'
    };
  }

  openCloseMenu(){
    this.setState({ mobileMenu: ( this.state.mobileMenu === 'opened' ) ? 'closed' : 'opened' })
  }
  closeMenu(){
    this.setState({ mobileMenu: 'closed' })
  }

  render() {
    return (
      <Router>
          <Route
            render={({ location }) => (
              <div>
                <div onClick={() => this.openCloseMenu()} id="openMenuButton" className={this.state.mobileMenu}><span></span><span></span><span></span></div>
                <header id="menu" className={this.state.mobileMenu}>
                  <ul>
                    <li><Link onClick={() => this.closeMenu()} title="Events" to="/events"><img src="img/menu_events.svg" alt="events" /></Link></li>
                    <li><Link onClick={() => this.closeMenu()} title="About" to="/about"><img src="img/menu_about.svg" alt="about" /></Link></li>
                    <li><Link onClick={() => this.closeMenu()} title="Home" to="/"><img src="img/menu_home.svg" alt="home" /></Link></li>
                  </ul>
                </header>

                <div id="content">
                  <TransitionGroup>
                    <CSSTransition key={location.key} classNames="fade" timeout={300}>
                      <Switch location={location}>
                        <Route exact path="/" component={Home}/>
                        <Route path="/about" component={About}/>
                        <Route path="/events" component={Events}/>
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </div>

              </div>
            )}
          />
      </Router>
    );
  }

}
