import React, { Component } from 'react';

import './footer.css';

import icon_face from './img/face.svg';
import icon_insta from './img/insta.svg';

export default class Footer extends Component {

  componentWillMount() {
  }

  render() {
    return (
      <div className="Footer">
        <div>
          <a href="mailto:info@lepetitcamion.com">INFO@LEPETITCAMION.COM</a>
          <a href="https://www.facebook.com/Le-Petit-Camion-1510528258995778/"><img src={icon_face} alt="facebook" /></a>
          <a href="https://www.instagram.com/lepetitcamioncoffee/"><img src={icon_insta} alt="instagram" /></a>
        </div>
      </div>
    );
  }

}
