import React, {Component} from 'react';
import VimeoPlayer from 'react-player/lib/players/Vimeo';

import './video.css';

import backgroundImage from './img/home_banner.jpg';


class Video extends Component {
    componentDidMount(){
      // setTimeout(function() { document.getElementById('background-video').classList.add('loaded'); }, 500);
    }


    render () {
        return (
            <div id="videoArea" style={{ backgroundImage: `url(${backgroundImage})` }}>
              <VimeoPlayer
                  id="background-video"
                  url='https://vimeo.com/271305595'
                  playing
                  width='100%'
                  height='100%'
                  onReady={() => document.getElementById('background-video').classList.add('loaded')}
                />
              <div id="logo">
                <img src="img/logo.svg" alt="Le Petit Camion" />
              </div>
            </div>
        )
    }

};

export default Video;
