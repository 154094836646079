import React, { Component } from 'react';

import Banner from '../banner/BannerComponent';
import Footer from '../footer/FooterComponent';

import './about.css';

import backgroundImage from './img/backgroundImage.jpg';
import introImage from './img/intro.jpg';
import leLittle from './img/the_little_truck.svg';
import lineImage from './img/line.png';
import altitudesImage from './img/altitudes.svg';
import specs from './img/specs.svg';
import specsImage from './img/truck_specs.png';



export default class About extends Component {

  componentWillMount() {
  }

  render() {
    return (
      <div className="About">



        <Banner source={backgroundImage} />



        <div id="intro">
          <div>
            <img src={leLittle} alt="Le Petit Camion" style={{ backgroundImage: `url(${lineImage})` }} />
            <p><b>The mobile unit is based on a Citroën H Van</b>, a light truck that was produced by the French car maker Citroën in 1972.</p>
          </div>
          <img src={introImage} alt="Camion" />
        </div>



        <div id="description">
          <div>
            <h5>Back in 1972, this Citroën H Van never predicted it would grow up to be residing in Qatar providing coffee to coffee lovers.</h5>
            <p>Le Petit Camion will be serving specialty coffee and introducing high-end coffee bean in the Qatari market. Within the last 5 years the coffee shops and bakeries has become a familiar feature of Qatari life. Every day, the demand for the coffee shop culture has increased in Qatar due to the cultural trend.</p>
          </div>
          <div>
            <p>People like to gather in one place to have coffee and chat. People are demanding quality from the market and would be interested more in the quality of the place they will spend their money in, as the local and expects people in Qatar travels and have more experience and are able to evaluate the good, average and bad product.</p>
            <p>Specialty coffee business is new in Qatar. However, it’s been growing in the region. In many years the number of outlets increased in Qatar.</p>
          </div>
        </div>

        <div id="altitudes">
          <h2>ALTITUDE EFFECTS ON COFFEE</h2>
          <img src={altitudesImage} alt="Altitudes" />
        </div>



        <div id="specs">

          <div className="partLeft">
            <img src={specs} alt="Truck Specs" />
            <p>It was developed as a simple front wheel driven van after World War II. The engine came from 11 CV while the gearbox, suspension and the interior were derived from those fitted to the 15CV. Unusually, the H had different wheelbases on each side. The superbly practical and aesthetically odd body was designed by Franchiset.</p>
            <p><b>A total of 473,289 were produced in 34 years in factories in France and Belgium until the end of production in December 1981.</b></p>
          </div>
          <div className="partRight">
            <div>
              <h5>THE CITROEN H VAN, TYPE H,  H-TYPE OR HY IS A PANEL VAN (LIGHT TRUCK) PRODUCED BY THE FRENCH CAR MAKER CITROEN BETWEEN 1947  AND 1981.</h5>
            </div>
          </div>

        </div>



        <div id="specsDetails">

          <img src={specsImage} alt="Truck Specs" />
          <div>
            <div>
              <p><b>Country of Origin:</b> France<br />
              <b>Model:</b> H Van<br />
              <b>Company:</b> Citroen<br />
              <b>Production Years:</b> 1947-1981<br />
              <b>Engine:</b> 4 cylinders in line, wet liners, bore 78mm, stroke 100mm, cubic capacity 1911cm3, 11 CV rating, 50 bhp @ 3800 rpm<br />
              <b>Transmission:</b> Front wheel drive via 3 speed manual gearbox, single dry plate clutch<br />
              <b>Brakes:</b> Hydraulically operated drums, handbrake operates on front wheels<br />
              <b>Sterring:</b> Rack and pinion</p>
            </div>
            <div>
              <p><b>Suspension:</b> Torsion bars, four shock absorbers on front, two on rear<br />
              <b>Dimensions:</b><br />
              <b>Length:</b> 4.28m<br />
              <b>Width:</b> 1.99m<br />
              <b>Wheelbase:</b> left _ 2.53 ; right _ 2.50m<br />
               <b>Front Track:</b> 1.61m<br />
              <b>Rear track:</b> 1,65m<br />
              <b>Unladen weight:</b> 1400kg<br />
              <b>Maximum payload:</b> 1200kg<br />
              <b>Maximum speed:</b> 78 kph<br />
              <b>Fuel consumption:</b> 13litres/100km</p>
            </div>
          </div>

        </div>





        <Footer />

      </div>
    );
  }

}
